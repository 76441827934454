const { ROLE_TYPE } = require("./constants");

const getUniqueOptions = (items, key) => {
    const map = new Map();
    items.forEach(item => {
        if (item[key] && item[key]._id) {
            map.set(item[key]._id, { label: item[key][`${key.split('_')[0]}_name`], value: item[key]._id });
        }
    });
    return Array.from(map.values());
};

const getRoleUser = (userAuth) => {

    let visibleRoles = [];

    switch (userAuth.role) {
        case 's_admin':
            visibleRoles = ROLE_TYPE;
            break;
        case 'm_admin':
            visibleRoles = ROLE_TYPE;
            break;
        case 'coach':
            visibleRoles = ROLE_TYPE.filter(role =>
                ['coach', 't_leader', 'member'].includes(role.value)
            );
            break;
        case 't_leader':
            visibleRoles = ROLE_TYPE.filter(role =>
                ['t_leader', 'member'].includes(role.value)
            );
            break;
        case 'member':
            visibleRoles = ROLE_TYPE.filter(role => role.value === 'member');
            break;
        default:
            visibleRoles = [];
            break;
    }

    return visibleRoles;
};

module.exports = {
    getUniqueOptions,
    getRoleUser
}