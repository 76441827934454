import React, { useState, useEffect } from "react";
import axiosClient from "../../api/axiosClient";
import Loader from "../../components/Loader";
import MessageBox from "./MessageBox";

const ChatBox = ({ account, isLogin, userSelectedId }) => {
  console.log("🚀 ~ ChatBox ~ isLogin:", isLogin);
  const [selectedDialog, setSelectedDialog] = useState();
  const [dialogs, setDialogs] = useState();
  const [messages, setMessages] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      fetchAndSyncDialog();
  }, [account]);

  const fetchAndSyncDialog = async () => {
    setLoading(true);
    try {
      const response = isLogin
        ? await axiosClient.get(
            `/telegram/sync-dialogs?account_id=${account._id}`
          )
        : await axiosClient.get(
            `/telegram/list-dialogs?account_id=${account._id}`
          );
      console.log("🚀 ~ fetchAndSyncDialog ~ response:", response);

      setDialogs(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  useEffect(() => {
    if (selectedDialog) {
      fetchMessages();
    }
  }, [selectedDialog]);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      // const response = await axiosClient.get(`/telegram/list-message-by-dialog?chat_id=${selectedDialog._id}`);
      const response = isLogin
        ? await axiosClient.get(
            `/telegram/sync-message?account_id=${account._id}&chat_id=${selectedDialog._id}`
          )
        : await axiosClient.get(
            `/telegram/list-message-by-dialog?chat_id=${selectedDialog._id}`
          );
      console.log("🚀 ~ fetchMessages ~ Messages:", response);
      setMessages(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching groups:", error);
      setLoading(false);
    }
  };

  const handleSelectDialog = async (dialog) => {
    setSelectedDialog(dialog);
  };

  return (
    <div className="flex-1 w-full px-1 flex flex-col">
      {dialogs && dialogs.length > 0 ? (
        <div className="flex">
          {/* Cột chứa danh sách dialog */}
          <div className="w-52 px-1 overflow-y-auto shadow-lg border border-gray-300 rounded">
            <h3 className="text-md font-semibold mb-2">Các cuộc trò chuyện</h3>
            <ul className="space-y-2 m-0">
              {dialogs.map((dialog, index) => (
                <li
                  key={index}
                  className={`p-2 bg-gray-200 font-semibold shadow-lg rounded cursor-pointer hover:bg-gray-500 ${
                    selectedDialog?._id === dialog?._id
                      ? "bg-gray-500 text-white"
                      : ""
                  }`}
                  onClick={() => handleSelectDialog(dialog)}
                >
                  {dialog.title}
                </li>
              ))}
            </ul>
          </div>

          {/* Cột chứa boxMessage */}
          <div className="w-full ml-2 px-2 shadow-lg border border-gray-300 h-screen rounded">
            {selectedDialog && (
              <MessageBox
                messages={messages}
                account={account}
                selectedDialog={selectedDialog}
                userSelectedId={userSelectedId}
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          <p>Tài khoản chưa có dữ liệu</p>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default ChatBox;
