import React from 'react';

const DeleteConfirmationDialog = ({ onClose, onConfirm }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
                <h2 className="text-xl mb-4">Xác nhận xóa</h2>
                <p>Bạn có chắc chắn muốn xóa đội này không?</p>
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                        onClick={onClose}
                    >
                        Hủy
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={onConfirm}
                    >
                      Xóa
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmationDialog;
