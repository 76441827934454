import React from 'react';
import Layout from '../layouts/Layout';

const Dashboard = () => {
  return (
    <Layout>
      <div className="min-h-screen bg-gray-100 p-6">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-800">Dashboard</h1>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="flex items-center">
              <div className="bg-purple-600 text-white p-3 rounded-full">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 15l4-4 4 4m0 6v-6m0 0l-4-4m4 4V3"
                  />
                </svg>
              </div>
              <div className="ml-4">
                <p className="text-gray-500">New Users</p>
                <p className="text-2xl font-semibold text-gray-800">8,282</p>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="flex items-center">
              <div className="bg-orange-600 text-white p-3 rounded-full">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 3v6h6M21 21v-6h-6"
                  />
                </svg>
              </div>
              <div className="ml-4">
                <p className="text-gray-500">Total Orders</p>
                <p className="text-2xl font-semibold text-gray-800">200,521</p>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="flex items-center">
              <div className="bg-pink-600 text-white p-3 rounded-full">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12H9m4 8H9m5-16h3m-7 0H9M4 6h3m13 2v2m-3 8v2m-7 0v2"
                  />
                </svg>
              </div>
              <div className="ml-4">
                <p className="text-gray-500">Available Products</p>
                <p className="text-2xl font-semibold text-gray-800">215,542</p>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Title
                </th>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Role
                </th>
                <th className="py-3 px-6"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <tr>
                <td className="py-4 px-6 whitespace-nowrap flex items-center">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://via.placeholder.com/150"
                    alt=""
                  />
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      John Doe
                    </div>
                    <div className="text-sm text-gray-500">john@example.com</div>
                  </div>
                </td>
                <td className="py-4 px-6 whitespace-nowrap">
                  <div className="text-sm text-gray-900">Software Engineer</div>
                  <div className="text-sm text-gray-500">Web dev</div>
                </td>
                <td className="py-4 px-6 whitespace-nowrap">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Active
                  </span>
                </td>
                <td className="py-4 px-6 whitespace-nowrap text-sm text-gray-500">
                  Owner
                </td>
                <td className="py-4 px-6 whitespace-nowrap text-right text-sm font-medium">
                  <a href="#" className="text-indigo-600 hover:text-indigo-900">
                    Edit
                  </a>
                </td>
              </tr>
              {/* Thêm nhiều dòng nếu cần */}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
