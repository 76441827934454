import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../../layouts/AuthLayout';

const Logout = () => {
    const navigate = useNavigate(); // Tạo navigate instance

    useEffect(() => {
        // Xóa token và refresh từ sessionStorage
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refresh');
        sessionStorage.clear();
        if (!sessionStorage.getItem('token') && !sessionStorage.getItem('refresh')) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <AuthLayout>
            <div>
                <h1>Logging out...</h1>
            </div>
        </AuthLayout>
    );
};

export default Logout;