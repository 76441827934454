// useSort.js
import { useState, useMemo } from 'react';

const useSort = (items) => {
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig.key) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const sort = (key, direction) => {
        setSortConfig({ key, direction });
    };

    return { sortedItems, sort };
};

export default useSort;
