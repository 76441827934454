import React, { useEffect, useState } from 'react';
import { MdArrowBack } from "react-icons/md";
import { IoArrowForwardOutline } from "react-icons/io5";

const TelegramList = ({ accounts, onSelect, hideTelegramSideBar, isListTelegramOpen }) => {
    const [selectedAccountId, setSelectedAccountId] = useState(null);

    const handleOnClick = (account) => {
        setSelectedAccountId(account._id);
        onSelect(account);
    }

    return (
        <>
            <div className={`${isListTelegramOpen ? 'w-40' : 'w-9'} bg-gray-100 p-1 rounded h-screen max-h-screen border shadow-lg border-gray-300`}>
                <div className="p-1 text-lg font-semibold mb-2 text-sky-500 hover:text-sky-700 cursor-pointer rounded flex items-center gap-2 max-h-screen" onClick={hideTelegramSideBar}>
                    {isListTelegramOpen ? (
                        <>
                            <span className='flex py-1 gap-3 justify-center items-center'><MdArrowBack /> Telegram</span>
                        </>
                    ) : (
                        <>
                            <span className=' flex py-2 gap-3 justify-center items-center'><IoArrowForwardOutline /></span>
                        </>
                    )}
                </div>
                {isListTelegramOpen && <ul className="flex-1 overflow-y-auto">
                    {accounts.map((account) => (
                        <li
                            key={account._id}
                            className={`p-2 mb-2 bg-gray-200 cursor-pointer hover:bg-gray-500 rounded shadow-lg ${selectedAccountId === account._id ? 'bg-gray-500 text-white' : ''}`}
                            onClick={() => handleOnClick(account)}
                        >
                            <div className="flex-col">
                                <div className='font-semibold uppercase text-blue-400'>@{account.telegram_id.username}</div>
                                <div className='font-semibold'>{account.phone_number}</div>
                            </div>
                        </li>
                    ))}
                </ul>
                }
            </div>
        </>
    );
};
export default TelegramList;