import React from 'react';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const FilterSortUI = ({ filters, onFilterChange }) => {
    return (
        <div className="flex gap-2">
            {/* Dynamic Filter Dropdowns */}
            {filters.map((filter, index) => (
                <select
                    key={index}
                    onChange={(e) => onFilterChange(filter.key, e.target.value)}
                    className="border p-2 rounded"
                >
                    <option value="">{`All ${filter.label}`}</option>
                    {filter.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ))}
        </div>
    );
};

export default FilterSortUI;
