import React from 'react';
import { FaSortUp, FaSortDown } from 'react-icons/fa';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const DynamicTable = ({ columns, data, sort, pagination }) => {
    const {
        currentPage,
        totalPages,
        goToNextPage,
        goToPreviousPage,
        setPage,
    } = pagination;

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                <thead className="bg-gray-200">
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index} className="table-cell text-left px-4 py-2 font-semibold text-gray-700">
                                <div className='flex items-center gap-1'>
                                    {column.label}
                                    {column.sortable && (
                                        <div className="flex flex-col items-center">
                                            <button onClick={() => sort(column.key, 'asc')} className="hover:text-gray-900 -mb-1.5">
                                                <FaSortUp />
                                            </button>
                                            <button onClick={() => sort(column.key, 'desc')} className="hover:text-gray-900 -mt-1.5">
                                                <FaSortDown />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="table-row-group overflow-y-auto max-h-96">
                    {data.map((row, rowIndex) => (
                        <tr key={row._id} className="table-row border-t text-left hover:bg-gray-100 ">
                            {columns.map((column, colIndex) => (
                                <td key={colIndex} className={`table-cell text-left px-4 py-2 text-gray-700 ${column.key === 'note' ? "text-ellipsis overflow-hidden max-w-28" : ""}`}>
                                    {column.render ? column.render(row) : row[column.key]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center p-4">
                <button
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:bg-gray-200"
                >
                    Previous
                </button>
                <span className="text-gray-700">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:bg-gray-200"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default DynamicTable;
