import React, { useState } from 'react';
import axiosClient from '../../api/axiosClient';
import AuthLayout from '../../layouts/AuthLayout';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosClient.post(`/auth/login`, { username, password });
            sessionStorage.setItem('token', response.accessToken);
            sessionStorage.setItem('refresh', response.refreshToken);
            navigate('/');
        } catch (err) {
            setError('Invalid credentials');
        }
    };

    return (
        <AuthLayout>
            <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                <h1 className="text-2xl font-bold mb-4">Login</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Mật Khẩu</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <button
                        type="submit"
                        className="mb-2 w-full bg-blue-500 text-white p-2 rounded"
                    >
                        Đăng Nhập
                    </button>
                    {/* <button type='submit'
                        className='w-full bg-blue-500 text-white p-2 rounded'
                    >
                        Đăng Ký
                    </button> */}
                </form>
            </div>
        </AuthLayout>
    );
};

export default Login;
