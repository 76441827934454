import React, { useState } from "react";
import {
  TextInput,
  Dropdown,
  PasswordInput,
  EmailInput
} from "../../components/UIComponents";
import { SOCIAL_TYPE, STATUS } from "../../utils/constants";
import Loader from "../../components/Loader";

const AccountForm = ({ account, handleSubmit, onChange, onClose, loading }) => {

  return (
    <>
      (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-lg p-6 z-50">
          <h2 className="text-2xl font-semibold mb-4">
            {" "}
            {!account ? "Tạo" : "Sửa"} Tài Khoản
          </h2>
          <form onSubmit={handleSubmit}>
            <Dropdown
              options={SOCIAL_TYPE}
              labelName="Type"
              fieldName="type"
              defaultValue={account?.type || SOCIAL_TYPE[0].value}
              onChange={onChange}
            />
            <TextInput
              fieldName="account_name"
              labelName="Tên Tài Khoản"
              defaultValue={account?.account_name}
              onChange={onChange}
            />
            <TextInput
              fieldName="acc_username"
              labelName="Username"
              defaultValue={account?.acc_username}
              onChange={onChange}
            />
            <PasswordInput
              fieldName="acc_password"
              labelName="Password"
              defaultValue={account?.acc_password || ''}
              onChange={onChange}
            />
            <TextInput
              fieldName="phone_number"
              labelName="Số điện thoại"
              defaultValue={account?.phone_number}
              onChange={onChange}
            />
            <EmailInput
              fieldName="email"
              labelName="Email"
              defaultValue={account?.email}
              onChange={onChange}
            />
            <Dropdown
              options={STATUS}
              fieldName="is_active"
              labelName="Trạng Thái"
              defaultValue={account ? account?.is_active : true}
              onChange={onChange}
            />
            <TextInput
              fieldName="note"
              labelName="Ghi chú"
              defaultValue={account?.note}
              onChange={onChange}
            />

            <div className="mt-6 flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={onClose}
          style={{ pointerEvents: "auto" }}
        ></div>
        {loading && <Loader />}
      </div>
      )
    </>
  );
};

export default AccountForm;
