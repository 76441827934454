import React, { useState, useEffect } from "react";
import { Dropdown, PasswordInput, TextInput } from "../../components/UIComponents";
import axiosClient from "../../api/axiosClient";
import { STATUS } from "../../utils/constants";
import { Toast } from "../../messages/Toast";
import AvatarUploader from "../../UploadFile/AvatarUploader";
import Loader from "../../components/Loader";
import { getRoleUser } from "../../utils/utils";

const CreateUserDialog = ({ open, onClose, onCreate }) => {
  const [loading, setLoading] = useState();
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const userAuth = JSON.parse(sessionStorage.getItem('user'));

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    group_id: "",
    team_id: "",
    full_name: "",
    role: "member",
    is_active: true,
    avatar: "",
    note: "",
  });


  const showToast = Toast();

  const handleAvatarUpload = (avatarUrl) => {
    setFormData({
      ...formData,
      avatar: avatarUrl,
    });
  };

  useEffect(() => {
    if (formData?.group_id) {
      setSelectedGroupId(formData.group_id);
    }
  }, [formData?.group_id]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axiosClient.get("/group");
        setFormData({
          ...formData,
          group_id: response[0]._id
        })
        handleGroupChange({ target: { value: response[0]._id, name: response[0].name } });
        setGroups(response);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };
    fetchGroups();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleGroupChange = async (e) => {
    const { name, value } = e.target;
    setSelectedGroupId(value);
    handleChange({ target: { name, value } });

    try {
      setLoading(true);
      const response = await axiosClient.get(`/team/by-group/${value}`);

      const listTeams = Array.isArray(response) ? response : [];
      setTeams(listTeams);
      setLoading(false);
      if (listTeams.length > 0) {
        // Set the team_id to the first team in the list
        setFormData((prevFormData) => ({
          ...prevFormData,
          team_id: listTeams[0]._id,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          team_id: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching teams:", error);
      showToast(error.response?.message || error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Kiểm tra group_id và team_id trước khi gửi yêu cầu
    if (!formData.group_id || !formData.team_id) {
      showToast(
        "Vui lòng nhập thông tin trước khi tạo.",
        "error",
        3000
      );
      return;
    }

    try {
      setLoading(true);
      const response = await axiosClient.post("/auth/create-user", formData);
      if (response.status === 'error') {
        showToast(response.error, 'error');
      } else {
        onCreate(response);
        showToast(" Tạo Người Dùng Thành công!", "success", 3000);
      }
      setLoading(false);
      onClose();
    } catch (error) {
      console.error("Error creating User:", error);
      showToast(" Tạo Người Dùng Thất bại!", "error", 3000);
      setLoading(false);
    }
  };

  return (
    open && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-full max-w-2xl mx-auto rounded-lg shadow-lg p-6 z-50">
          <h2 className="text-2xl font-semibold mb-4">Tạo Nhân viên</h2>
          <div className="flex space-x-4">
            {/* Cột 1 */}
            <div className="flex-1">
              <TextInput
                fieldName="username"
                labelName="Username"
                defaultValue=""
                onChange={handleChange}
              />
              <PasswordInput
                fieldName="password"
                labelName="Password"
                defaultValue=""
                onChange={handleChange}
                is_required
              />
              <TextInput
                fieldName="full_name"
                labelName="Họ tên"
                defaultValue=""
                onChange={handleChange}
              />
              <AvatarUploader onUpload={(handleAvatarUpload)} />

            </div>
            <div className="flex-1">
              <Dropdown
                options={groups.map((group) => ({
                  value: group._id,
                  label: group.group_name,
                }))}
                fieldName="group_id"
                labelName="Khu"
                defaultValue={selectedGroupId}
                onChange={handleGroupChange}
              />
              <Dropdown
                options={teams.map((team) => ({
                  value: team._id,
                  label: team.team_name,
                }))}
                fieldName="team_id"
                labelName="Tổ"
                defaultValue={formData?.team_id || teams[0]?.id}
                onChange={handleChange}
              />
              <Dropdown
                options={getRoleUser(userAuth)}
                fieldName="role"
                labelName="Chức vụ"
                defaultValue={formData?.role}
                onChange={handleChange}
              />
              <Dropdown
                options={STATUS}
                fieldName="is_active"
                labelName="Trạng Thái"
                defaultValue={formData?.is_active}
                onChange={handleChange}
              />
              <TextInput
                fieldName="note"
                labelName="Ghi Chú"
                defaultValue=""
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Đóng
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleSubmit}
            >
              Tạo mới
            </button>
          </div>
        </div>
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={onClose}
          style={{ pointerEvents: "auto" }}
        ></div>
        {loading && <Loader />}
      </div>
    )
  );
};

export default CreateUserDialog;
