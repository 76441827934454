import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";
import { FaLayerGroup } from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import { MdArrowBack } from "react-icons/md";
import { IoArrowForwardOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { PiTelegramLogoBold } from "react-icons/pi";
import { useLocation } from 'react-router-dom';

const Sidebar = ({ user }) => {
  const role = user.role;
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsVisible(!isVisible);
  };

  const links = [
    { to: '/', id: 1, label: 'Dashboard', roles: ['s_admin', 'm_admin'], icon: <IoHomeOutline /> },
    { to: '/groups', id: 2, label: 'Quản Lý Khu', roles: ['s_admin', 'm_admin', 'coach'], icon: <FaLayerGroup /> },
    { to: '/teams', id: 3, label: 'Quản Lý Tổ', roles: ['s_admin', 'm_admin', 'coach', 't_leader'], icon: <RiTeamLine /> },
    { to: '/users', id: 4, label: 'Nhân Viên', roles: ['s_admin', 'm_admin', 'coach', 't_leader', 'member'], icon: <FaRegUser /> },
    { to: '/telegrams', id: 5, label: 'Telegram', roles: ['s_admin', 'coach', 't_leader', 'member'], icon: <PiTelegramLogoBold /> },
  ];

  return (
    <div className="relative bg-white border-r-2 font-semibold uppercase">
      {/* Sidebar */}

      <div className={`${!!isVisible ? 'w-44' : 'w-10'} h-auto p-2`} >
        {isVisible && <ul>
          {links
            .filter(link => link.roles.includes(role))
            .map(link => (
              <li key={link.to} className='mb-2' >
                <Link to={link.to}>
                  <div className={`flex justify-start items-center rounded px-1 py-2 gap-x-2 bg-sky-300 border border-sky-300 shadow-lg hover:bg-orange-400 ${location.pathname === link.to ? 'bg-orange-400' : 'bg-sky-300'}`}>
                    {link.icon} {link.label}
                  </div>
                </Link>
              </li>
            ))}
        </ul>
        }
        <button
          onClick={toggleSidebar}
          className="z-100 focus:outline-none "
        >
          {isVisible ? <MdArrowBack size={28} /> : <IoArrowForwardOutline size={28} />}
        </button>
      </div>
    </div >
  );
};

export default Sidebar;
