import React, { useEffect, useState } from "react";
import axiosClient from "../../api/axiosClient";
import AvatarUploader from "../../UploadFile/AvatarUploader";
import { Toast } from "../../messages/Toast";
import Loader from "../../components/Loader";
import { DEFAULT_IMG2 } from "../../utils/constants";

const EditGroupDialog = ({ open, onClose, group, onUpdate }) => {
  const showToast = Toast();
  const [loading, setLoading] = useState(false);
  const [editFormData, setEditFormData] = useState({
    subsidiary: group?.subsidiary || "",
    group_name: group?.group_name || "",
    slogan: group?.slogan || "",
    avatar: group?.avatar || DEFAULT_IMG2,
  });

  useEffect(() => {
    if (group) {
      setEditFormData({
        subsidiary: group?.subsidiary || "",
        group_name: group?.group_name || "",
        slogan: group?.slogan || "",
        avatar: group?.avatar || DEFAULT_IMG2,
      });
    }
  }, []);

  const [errors, setErrors] = useState({
    group_name: "",
    slogan: "",
  });

  const handleUploadClick = (avatarUrl) => {
    setEditFormData({
      ...editFormData,
      avatar: avatarUrl,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };

  const handleEditSubmit = async () => {
    // Kiểm tra lần cuối trước khi gửi
    if (errors?.group_name || errors.slogan) {
      return;
    }

    try {
      setLoading(true);
      const apiUrl = `/group/${group._id}`;
      const response = await axiosClient.put(apiUrl, editFormData);
      onUpdate(group._id, editFormData);
      showToast("Chỉnh sửa nhóm thành công!");
      setLoading(false);
    } catch (error) {
      console.error(
        "Error updating group:",
        error.response?.data || error.message
      );
      showToast(error.response?.data || error.message, "error");
      setLoading(false);
    }
  };

  return (
    <>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-full max-w-2xl mx-auto rounded-lg shadow-lg p-6 z-50">
            <h2 className="text-2xl font-semibold mb-4">Chỉnh sửa Khu</h2>
            <div className="space-y-4">
              <input
                type="text"
                name="subsidiary"
                value={editFormData?.subsidiary}
                readOnly
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring border-gray-300 bg-gray-100"
              />
              <input
                type="text"
                name="group_name"
                placeholder="Group Name"
                value={editFormData?.group_name}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring ${errors.group_name ? "border-red-500" : "border-gray-300"
                  }`}
              />
              <p className="text-red-500 text-sm">{errors.group_name}</p>
              <input
                type="text"
                name="slogan"
                placeholder="Slogan"
                value={editFormData?.slogan}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring ${errors.slogan ? "border-red-500" : "border-gray-300"
                  }`}
              />
              <p className="text-red-500 text-sm">{errors.slogan}</p>
            </div>
            <AvatarUploader onUpload={handleUploadClick} defaultAvatar={editFormData?.avatar} />
            <div className="mt-6 flex justify-end space-x-2">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleEditSubmit}
                disabled={!!errors.group_name || !!errors.slogan}
                className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${!!errors.group_name || !!errors.slogan
                  ? "opacity-50 cursor-not-allowed"
                  : ""
                  }`}
              >
                Save
              </button>
            </div>
          </div>
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={onClose}
          ></div>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default EditGroupDialog;
