import React, { useState, createContext, useContext } from 'react';
import { FaTimes } from 'react-icons/fa';

const ToastContext = createContext();

export const Toast = () => {
    return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
    const [toast, setToast] = useState({ message: '', type: '', visible: false });

    const showToast = (message, type = 'success', duration = 3000) => {
        setToast({ message, type, visible: true });
        setTimeout(() => {
            setToast({ message: '', type: '', visible: false });
        }, duration);
    };

    const getToastStyles = (type) => {
        switch (type) {
            case 'success':
                return 'bg-green-500 text-white';
            case 'error':
                return 'bg-red-500 text-white';
            default:
                return 'bg-gray-800 text-white';
        }
    };

    return (
        <ToastContext.Provider value={showToast}>
            {children}
            {toast.visible && (
                <div
                    className={`fixed top-4 right-4 ${getToastStyles(toast.type)} px-6 py-4 rounded-lg shadow-2xl flex items-center space-x-4 transform transition-all duration-500 ease-in-out ${
                        toast.visible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
                    }`}
                    style={{
                     
                        zIndex: 9999, // Đảm bảo nó nằm trên tất cả các thành phần khác
                    }}
                >
                    <span className="font-semibold text-lg">{toast.message}</span>
                    <button onClick={() => setToast({ ...toast, visible: false })} className="text-2xl">
                        <FaTimes />
                    </button>
                </div>
            )}
        </ToastContext.Provider>
    );
};
