import React, { useState, useEffect } from 'react';
import axiosClient from '../../api/axiosClient';
import { Toast } from "../../messages/Toast";
import Loader from '../../components/Loader';


const TelegramLoginDialog = ({ accountSelected, isOpen, onClose, onLoginSuccess }) => {
    const showToast = Toast();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState(accountSelected);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const [phase, setPhase] = useState(1);
    const [formData, setFormData] = useState({
        account_id: account?._id || '',
        phone_number: account?.phone_number,
        api_id: '',
        api_hash: '',
        password: '',
        otp: '',
    });

    useEffect(() => {
        if (account) {
            setFormData({
                account_id: account._id || '',
                phone_number: account.phone_number || '',
                api_id: account?.telegram_id?.api_id,
                api_hash: account?.telegram_id?.api_hash,
                password: account?.telegram_id?.password,
                otp: '',
            });
        }
    }, [account]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmitPhase1 = async () => {
        setLoading(true);
        try {
            const body = {
                account_id: formData.account_id,
                phone_number: formData.phone_number,
                api_id: formData.api_id,
                api_hash: formData.api_hash,
                password: formData.password,
            }
            const response = await axiosClient.post('/telegram/send-otp', body)
            if (response?.phoneCodeHash) {
                setPhase(2);
                setLoading(false)
                showToast("send OTP thành công, vui lòng kiểm tra Telegram của bạn")
            } else {
                console.log(response.message);
                showToast(response.message, 'error');
                setLoading(false);
            }

        } catch (error) {
            console.log("erorr send otp:", error.message)
            showToast(
                "Gửi OTP thất bại, vui lòng kiểm tra lại",
                "error",
                3000
            );
            setLoading(false)
        }
    };

    const handleSubmitPhase2 = async () => {
        setLoading(true);
        try {
            const body = {
                account_id: formData.account_id || '',
                otp: formData.otp || ''
            }
            const response = await axiosClient.post('/telegram/login', body)
            if (response.status === 'error') {
                showToast("Đăng nhập thất bại")
                setLoading(false)
                return;
            }
            showToast("Đăng nhập thành công")
            setLoading(false);
        } catch (error) {
            showToast(
                "Gửi OTP thất bại, vui lòng kiểm tra lại",
                "error",
                3000
            );
            setLoading(false)
        }
        console.log('Phase 2 data:', formData.otp);
        onClose();
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center ${isOpen ? 'block' : 'hidden'}`}>
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md z-50">
                <h2 className="text-xl font-bold mb-2">{phase === 1 ? 'Bước 1: Nhập Thông Tin Mã bảo mật' : 'Bước 2: Nhập OTP trong Telegram'}</h2>
                {phase === 1 && (
                    <div>
                        Truy cập{' '}
                        <a
                            className='text-blue-500 underline'
                            href='https://my.telegram.org/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            đây
                        </a>{' '}
                        tạo Application
                    </div>
                )}
                {phase === 1 && (
                    <div className='mt-4'>
                        <div className="mb-4">
                            <label className="block text-gray-700">Phone number</label>
                            <input
                                type="text"
                                name="api_id"
                                value={formData?.phone_number || ''}
                                onChange={(e) => handleChange(e)}
                                className="w-full px-3 py-2 border rounded"
                                readOnly
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">API ID</label>
                            <input
                                type="text"
                                name="api_id"
                                placeholder='12414326'
                                value={+formData?.api_id || null}
                                onChange={(e) => handleChange(e)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">API Hash</label>
                            <input
                                type="text"
                                placeholder='a47f900a15591e5ea4a991210babd7a2'
                                name="api_hash"
                                value={formData?.api_hash || ''}
                                onChange={(e) => handleChange(e)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formData?.password || ''}
                                    onChange={(e) => handleChange(e)}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-600"
                                >
                                    {showPassword ? 'Hide' : 'Show'}
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button onClick={handleSubmitPhase1} type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                                Next
                            </button>
                        </div>
                    </div>
                )}
                {phase === 2 && (
                    <div>
                        <div className="mb-4">
                            <label className="block text-gray-700"></label>
                            <input
                                type="text"
                                name="otp"
                                value={formData.otp}
                                onChange={(e) => handleChange(e)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="flex justify-between">
                            <button type="button" onClick={() => setPhase(1)} className="bg-gray-500 text-white px-4 py-2 rounded">
                                Back
                            </button>
                            <button onClick={handleSubmitPhase2} type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                                Login
                            </button>
                        </div>
                    </div>
                )}
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
                    X
                </button>
            </div>
            <div
                className="fixed inset-0 bg-black opacity-50 z-40"
                onClick={onClose}
                style={{ pointerEvents: "auto" }}
            ></div>
            {loading && <Loader />}
        </div>

    );
};

export default TelegramLoginDialog;
