import React from 'react';
import AuthLayout from '../../layouts/Layout';

const Settings = () => {
    return (
        <AuthLayout>
            <div>
                <h1>Settings Page</h1>
                <p>This is the settings page.</p>
            </div>
        </AuthLayout>
    );
};

export default Settings;
