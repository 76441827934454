import React, { useState, useEffect } from "react";
import axiosClient from "../../api/axiosClient";
import Layout from "../../layouts/Layout";
import EditAccountDialog from "./EditAccountDialog";
import CreateAccountDialog from "./CreateAccountDialog";
import { useParams } from "react-router-dom";
import { ButtonEdit, ButtonStatus } from '../../components/UIComponents'
import { LabelSocial } from "../../components/Label";
import Loader from "../../components/Loader";
import { DEFAULT_IMG } from "../../utils/constants";
import DynamicTable from "../../components/DynamicTable";
import usePagination from "../../hooks/usePagination";
import useSort from "../../hooks/useSort";
import useFilter from "../../hooks/useFilter";
import { Toast } from '../../messages/Toast';

const AccountList = () => {
    const showToast = Toast();
    const { user_id } = useParams();
    const [accounts, setAccounts] = useState([]);
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const { filteredItems: filterAccounts, setFilter } = useFilter(accounts);
    const { sortedItems: sortedAccounts, sort } = useSort(filterAccounts, { key: 'account_name', direction: 'asc' });
    const {
        currentPage,
        totalPages,
        currentItems: paginatedAccounts,
        goToNextPage,
        goToPreviousPage,
        setPage,
    } = usePagination(sortedAccounts, 15);

    useEffect(() => {
        setLoading(true);
        fetchAccounts();
    }, []);

    const fetchAccounts = async () => {
        try {
            const response = await axiosClient.get(`/account/by-user/${user_id}`);
            const data = response;
            setUser(data.user);
            setAccounts(data.accounts);
            return data;
        } catch (error) {
            console.error("Error fetching accounts:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditOpen = (account) => {
        setSelectedAccount(account);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setSelectedAccount(null);
    };

    const handleCreateOpen = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
    };

    const handleAccountUpdate = (accountId, updatedData) => {
        const updatedAccounts = accounts.map((account) =>
            account._id === accountId ? { ...account, ...updatedData } : account
        );
        setAccounts(updatedAccounts);
    };

    const handleAccountCreate = (newAccount) => {
        setAccounts([...accounts, newAccount]);
    };

    const handleConfirmDeleteOpen = (account) => {
        setSelectedAccount(account);
        setConfirmDeleteOpen(true);
    };

    const handleConfirmDeleteClose = () => {
        setConfirmDeleteOpen(false);
        setSelectedAccount(null);
    };

    const handleToggle = async (account) => {
        try {
            setLoading(true);
            await axiosClient.patch(`/account/${account._id}`);
            await fetchAccounts();
            showToast('Cập nhật trạng thái thành công');
        } catch (error) {
            console.error("Error updating account:", error);
            showToast(error.response?.message || error.message, 'error');
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = async () => {
        if (selectedAccount) {
            try {
                setLoading(true);
                await axiosClient.delete(`/ account / ${selectedAccount._id}`);
                setAccounts(
                    accounts.filter((account) => account._id !== selectedAccount._id)
                );
                showToast('Xoá Tài khoản thành công');
            } catch (error) {
                console.error("Error deleting account:", error);
                showToast(error.response?.message || error.message, 'error');
            } finally {
                setLoading(false);
            }
            setConfirmDeleteOpen(false);
        }
    };

    const accountColumn = [
        {
            key: 'type', label: 'Type', render: (row) => <LabelSocial type={row.type || '--'} />, sortable: false
        },
        { key: 'account_name', label: 'Tên tài khoản', render: (row) => row.account_name || '--', sortable: true },
        { key: 'acc_username', label: 'Username', render: (row) => row.acc_username || '--', sortable: true },
        { key: 'acc_password', label: 'Password', render: (row) => row.acc_password || '--', sortable: true },
        { key: 'phone_number', label: 'Số điện thoại', render: (row) => row.phone_number || '--', sortable: true },
        { key: 'email', label: 'Email', render: (row) => row.email || '--', sortable: true },
        {
            key: 'is_active',
            label: 'Trạng Thái',
            render: (row) => (
                <ButtonStatus isActive={row.is_active} onClick={() => handleToggle(row)} />
            ),
            sortable: true,
        },
        { key: 'createdAt', label: 'Ngày Tạo', render: (row) => new Date(row?.createdAt || '--').toLocaleDateString(), sortable: true },
        { key: 'updatedAt', label: 'Ngày Sửa', render: (row) => new Date(row?.updatedAt || '--').toLocaleDateString(), sortable: true },
        { key: 'note', label: 'Ghi Chú', render: (row) => row?.note || '--', sortable: false },
        {
            key: 'actions',
            label: 'Hoạt Động',
            render: (row) => (
                <div className="flex space-x-2">
                    <ButtonEdit onClick={() => handleEditOpen(row)} nameBtn="TK" />
                    <button
                        onClick={() => handleConfirmDeleteOpen(row)}
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                    >
                        Xoá TK
                    </button>
                </div>
            ),
            sortable: false,
        },
    ]

    return (
        <Layout>
            <div className="mx-auto p-4">
                <h2 className="text-2xl font-bold">Danh sách tài khoản của <span className="text-red-500">{user.full_name}</span></h2>
                <div className="flex justify-end mb-4 gap-2">
                    {/* Create Account Button */}
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={handleCreateOpen}
                    >
                        Thêm Tài Khoản
                    </button>
                </div>
                <DynamicTable columns={accountColumn}
                    data={paginatedAccounts}
                    sort={sort}
                    pagination={{
                        currentPage,
                        totalPages,
                        goToNextPage,
                        goToPreviousPage,
                        setPage,
                    }} />

                <CreateAccountDialog
                    user_id={user_id}
                    open={createOpen}
                    onClose={handleCreateClose}
                    onCreate={handleAccountCreate}
                />

                {selectedAccount && (
                    <EditAccountDialog
                        open={editOpen}
                        onClose={handleEditClose}
                        account={selectedAccount}
                        onUpdate={handleAccountUpdate}

                    />
                )}

                {confirmDeleteOpen && selectedAccount && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-lg p-6 z-50">
                            <h2 className="text-2xl font-semibold mb-4">Confirm Deletion</h2>
                            <p>
                                Are you sure you want to delete the account{" "}
                                <strong>{selectedAccount.username}</strong>?
                            </p>
                            <div className="mt-6 flex justify-end space-x-2">
                                <button
                                    onClick={handleConfirmDeleteClose}
                                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDelete}
                                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                        <div
                            className="fixed inset-0 bg-black opacity-50 z-40"
                            onClick={handleConfirmDeleteClose}
                            style={{ pointerEvents: "auto" }}
                        ></div>
                    </div>
                )}
                {loading && <Loader />}
            </div>
        </Layout >
    );
};

export default AccountList;
