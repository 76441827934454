import { Routes, Route } from 'react-router-dom';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import UserList from '../pages/UserManagement/UserList';
import GroupList from '../pages/GroupManagement/GroupList';
import TeamList from '../pages/TeamManagement/TeamList';
import ProtectedRoute from '../components/ProtectedRoute';
import Profile from '../pages/Auth/Profile';
import Settings from '../pages/Auth/Setting';
import Logout from '../pages/Auth/Logout';
import AccountList from '../pages/AccountManagement/AccountList';
import TelegramPage from '../pages/TelegramManagement/TelegramPage';


const AppRoutes = () => {
    return (
        <Routes>
            {/* Auth */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />


            <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
            <Route path="/settings" element={<ProtectedRoute element={Settings} />} />
            <Route path="/logout" element={<ProtectedRoute element={Logout} />} />
            {/* Management */}
            <Route path="/" element={<ProtectedRoute element={Dashboard} />} />
            <Route path="/users" element={<ProtectedRoute element={UserList} />} />
            <Route path="/accounts/:user_id" element={<ProtectedRoute element={AccountList} />} />

            <Route path="/groups" element={<ProtectedRoute element={GroupList} />} />
            <Route path="/teams" element={<ProtectedRoute element={TeamList} />} />
            <Route path="/telegrams" element={<ProtectedRoute element={TelegramPage} />} />
            {/* 404 */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;