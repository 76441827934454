import { useState } from 'react';

const usePagination = (items, itemsPerPage) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(items.length / itemsPerPage);

    const currentItems = items.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const goToNextPage = () => {
        setCurrentPage((page) => Math.min(page + 1, totalPages));
    };

    const goToPreviousPage = () => {
        setCurrentPage((page) => Math.max(page - 1, 1));
    };

    const setPage = (page) => {
        setCurrentPage(Math.max(1, Math.min(page, totalPages)));
    };

    return {
        currentPage,
        totalPages,
        currentItems,
        goToNextPage,
        goToPreviousPage,
        setPage,
    };
};

export default usePagination;
