import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/Layout';
import SearchBox from '../../components/SearchBox';
import ChatBox from './ChatBox';
import TelegramList from './TelegramList';
import TelegramLoginDialog from './TelegramLoginDialog';
import axiosClient from '../../api/axiosClient';


const TelegramPage = () => {
    const [accounts, setAccounts] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [userSelectedId, setUserSelectedId] = useState(null);
    const [isLogin, setIsLogin] = useState(false);
    const [isListTelegramOpen, setIsListTelegramOpen] = useState(true);
    const userAuth = JSON.parse(sessionStorage.getItem('user'));

    const fetchAccount = async (account_id) => {
        try {
            const response = await axiosClient.get(`/account/${account_id}`);
            setSelectedAccount(response);
            setIsLogin(response.telegram_id?.is_login)
            return response;
        } catch (error) {
            console.error("Error fetching groups:", error);
        }
    }

    const handleCloseDialog = async () => {
        await fetchAccount(selectedAccount._id);
        setIsDialogOpen(false);
    };

    const handleToggleHideListTelegram = () => {
        setIsListTelegramOpen(!isListTelegramOpen);
    };

    const handleSelect = async (account) => {
        setSelectedAccount(null);  // Reset trước khi fetch dữ liệu mới
        await fetchAccount(account._id); // Fetch lại dữ liệu account được chọn
        if (!account.telegram_id?.is_login) {
            setIsDialogOpen(true);
        } else {
            setIsLogin(true);
        }
    };

    const handleChangeTelegrams = (accounts, userId) => {
        setSelectedAccount(null);
        setAccounts(accounts);
        setUserSelectedId(userId);
    }

    return (
        <Layout>
            <ToastContainer position="top-right" autoClose={5000} />
            <div className="container mx-auto p-4">
                <SearchBox handleChangeTelegrams={handleChangeTelegrams} />
                <div className='flex mt-2'>
                    {/* List Telegram */}
                    {accounts && <TelegramList accounts={accounts} onSelect={handleSelect} hideTelegramSideBar={handleToggleHideListTelegram} isListTelegramOpen={isListTelegramOpen} />}

                    {/* Box chat */}
                    {console.log("selectedAccount", selectedAccount)}
                    
                    {selectedAccount && <ChatBox account={selectedAccount} isLogin={selectedAccount?.telegram_id?.is_login} userSelectedId={userSelectedId} />}
                </div>

            </div>
            {/* handle login account */}
            {
                userAuth._id === userSelectedId && !isLogin && isDialogOpen && selectedAccount && (
                    <TelegramLoginDialog accountSelected={selectedAccount} isOpen={isDialogOpen} onClose={handleCloseDialog} />
                )
            }
        </Layout >
    );
};

export default TelegramPage;
