import { useState, useMemo } from 'react';

/**
 * A custom hook to filter a list of items based on various criteria,
 * including filtering by `key` or `key._id`.
 * @param {Array} items - The list of items to filter.
 * @param {Object} initialFilters - Initial filter values.
 * @returns {Object} - The filtered items and the setFilter function.
 */
const useFilter = (items, initialFilters = {}) => {
    // State to hold current filter criteria
    const [filters, setFilters] = useState(initialFilters);

    // Memoized filtered items to avoid unnecessary computations
    const filteredItems = useMemo(() => {
        if (!items) return [];

        return items.filter(item => {
            // Check if the item meets all active filter criteria
            return Object.keys(filters).every(key => {
                // Skip empty filter values
                if (!filters[key]) return true;

                // Check both `item[key]` and `item[key]._id`
                const itemValue = item[key];

                // If the value is an object with an _id, compare it with the filter
                if (itemValue && typeof itemValue === 'object' && '_id' in itemValue) {
                    return String(itemValue._id) === String(filters[key]);
                }

                // Otherwise, compare the primitive value of `item[key]`
                return String(itemValue) === String(filters[key]);
            });
        });
    }, [items, filters]);

    /**
     * Updates the filter criteria for a specific key.
     * @param {string} key - The key of the filter (e.g., 'group_id', 'team_id').
     * @param {any} value - The value to filter by.
     */
    const setFilter = (key, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [key]: value,
        }));
    };

    return {
        filteredItems,
        setFilter,
    };
};

export default useFilter;