import React from 'react';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center">
      <h1 className="text-5xl font-bold text-red-500 mb-4">404</h1>
      <p className="text-xl mb-6">Oops! Trang bạn tìm kiếm không tồn tại.</p>
      <a href="/" className="text-blue-500 hover:underline">Quay lại Trang chủ</a>
    </div>
  );
};

export default NotFound;