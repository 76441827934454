import React, { useState, useEffect } from 'react';
import axiosClient from '../../api/axiosClient';
import { LabelRole } from '../../components/Label';
import { ButtonStatus } from '../../components/UIComponents';
import Layout from '../../layouts/Layout';
import Loader from '../../components/Loader';

const Profile = () => {
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axiosClient.get('/auth/fetch');
                setUser(response);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);


    if (loading) {
        return <Loader />
    }
    return (
        <Layout>
            <div className='flex-col rounded-xl sm:rounded-lg'>
                <div
                    className="py-8 px-8 mx-auto bg-white  shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                    <img className="block mx-auto h-24 rounded-full sm:mx-0 sm:shrink-0" src={user?.avatar ? user?.avatar : "https://tailwindcss.com/img/erin-lindford.jpg"} alt="Woman's Face" />
                    <div className="text-center space-y-2 sm:text-left">
                        <div className="space-y-0.5">
                            <p className="text-lg text-black font-semibold">
                                {user.full_name}
                            </p>
                        </div>
                        <LabelRole role={user?.role} />
                    </div>
                </div>
                <div className="bg-white shadow overflow-hidden ">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Giới thiệu:
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Full name:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {user.full_name}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Trạng Thái:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <ButtonStatus isActive={user.is_active} />
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default Profile;
