import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Icons for toggling
import axiosClient from '../api/axiosClient'; // Assuming axios is used for API calls
import Loader from './Loader';

const SearchBox = ({ handleChangeTelegrams }) => {
    const [showSearch, setShowSearch] = useState(false);
    const [groups, setGroups] = useState([]);
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedTeamId, setSelectedTeamId] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchGroups();
        fetchUsers();
    }, []);

    useEffect(() => {
        if (selectedGroupId) {
            fetchTeams(selectedGroupId);
        }
    }, [selectedGroupId]);

    const fetchGroups = async () => {
        try {
            const response = await axiosClient.get('/group');
            setGroups(response || []);
            if (response.length > 0) {
                setSelectedGroupId(response[0]._id)
            } else {
                setSelectedGroupId('');
            }
        } catch (error) {
            console.error('Failed to fetch groups:', error);
        }
    };

    const fetchTeams = async (selectedGroupId) => {
        try {
            const response = await axiosClient.get(`/team/by-group/${selectedGroupId}`);
            const listTeams = Array.isArray(response) ? response : [];
            setTeams(listTeams);
            setSelectedTeamId(listTeams[0]?._id || '');
        } catch (error) {
            console.error("Error fetching teams:", error);
        } finally {
            setLoading(false);
        }
    }

    const handleGroupChange = async (e) => {
        const { name, value } = e.target;
        setSelectedGroupId(value);
        setLoading(true);
        fetchTeams(value);
    };

    const fetchUsers = async () => {
        try {
            const response = await axiosClient.get('/user', {
                params: {
                    group_id: selectedGroupId,
                    team_id: selectedTeamId,
                    full_name: searchTerm,
                },
            });
            setUsers(response || []);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        setLoading(true);
        fetchUsers();
    };

    const handleSelectUser = async (userId) => {
        setLoading(true);
        try {
            const response = await axiosClient.get(`/account/by-user/${userId}?type=telegram`);
            setUserSelected(response?.user || {})
            handleChangeTelegrams(response?.accounts || [], userId);
            setLoading(false)
        } catch (error) {
            console.error('Failed to fetch user accounts:', error);
            setLoading(false)
        }
    };

    return (
        <div className="p-2 border border-gray-300 rounded">
            <button
                onClick={() => setShowSearch(!showSearch)}
                className="mb-2 flex items-center text-gray-600 text-lg"
            >
                Tìm kiếm nhân viên
                {showSearch ? <FaChevronDown size={20} className="ml-2" /> : <FaChevronUp size={20} className="ml-2" />}
            </button>

            {showSearch && (
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <select
                            value={selectedGroupId}
                            onChange={(e) => handleGroupChange(e)}
                            className="w-full p-2 border"
                        >
                            {groups.map(group => (
                                <option key={group._id} value={group._id}>{group.group_name}</option>
                            ))}
                        </select>
                    </div>

                    {loading && (
                        <Loader />
                    )}

                    <div className="col-span-1">
                        <select
                            value={selectedTeamId}
                            onChange={(e) => setSelectedTeamId(e.target.value)}
                            className="w-full p-2 border"
                        >
                            {teams.map(team => (
                                <option key={team._id} value={team._id}>{team.team_name}</option>
                            ))}
                        </select>
                    </div>


                    <div className="col-span-1">
                        <input
                            type="text"
                            placeholder="Search by name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full p-2 border"
                        />
                    </div>

                    <div className="col-span-1">
                        <button onClick={handleSearch} className="w-full p-2 bg-blue-500 text-white">
                            Search
                        </button>
                    </div>

                    <div className="col-span-4">
                        <select
                            onChange={(e) => handleSelectUser(e.target.value)}
                            className="w-full p-2 border"
                        >
                            <option value="">Select User</option>
                            {users.map(user => (
                                <option key={user._id} value={user._id}>{user.full_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchBox;
