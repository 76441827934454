import React, { useState } from 'react';

const Header = ({ user }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="bg-gray-900 text-white p-4 flex justify-between items-center w-screen">
      {/* Logo */}
      <a className="flex items-center space-x-3" href={process.env.PUBLIC_URL}>
        <img src={`${process.env.PUBLIC_URL}/sh-logo.jpg`} alt="Logo" className="w-12 h-12 rounded-full" />
        <h2 className="text-2xl font-bold">SHBET-CRM</h2>
      </a>

      {/* User Section */}
      <div className="flex items-center space-x-4">
        {!user && (
          <>
            <a href="#login" className="text-blue-400 hover:underline">Log in</a>
            <a href="#signup" className="text-blue-400 hover:underline">Sign up</a>
          </>
        )}

        {/* If user is logged in */}
        {user && (
          <div className="relative">
            <img
              src={user.avatar ? user.avatar : `${process.env.PUBLIC_URL}/avatar-default.jpg`}
              alt="User Avatar"
              className="w-8 h-8 rounded-full cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-lg">
                <a href="/" className="block px-4 py-2 hover:bg-gray-100 rounded-lg">Trang chủ</a>
                <a href="/profile" className="block px-4 py-2 hover:bg-gray-100 rounded-lg">Thông tin đăng nhập</a>
                <a href="/settings" className="block px-4 py-2 hover:bg-gray-100 ">Cài đặt</a>
                <a href="/logout" className="block px-4 py-2 hover:bg-gray-100 rounded-lg">Thoát ra</a>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;




