import React, { useState, useEffect } from "react";
import axiosClient from "../../api/axiosClient";
import AccountForm from "./AccountForm";
import { Toast } from "../../messages/Toast";

const EditAccountDialog = ({ open, onClose, account, onUpdate }) => {
    const showToast = Toast();
    const [loading, setLoading] = useState();
    
    const [formData, setFormData] = useState({
        user_id: "",
        type: "",
        account_name: "",
        acc_username: "",
        acc_password: "",
        phone_number: "",
        email: "",
        is_active: true,
        note: ""
    });

    useEffect(() => {
        if (open && account) {
            console.log("Account data:", account); // Kiểm tra giá trị của account khi component mở
            setFormData({
                user_id: account?.user_id._id || "",
                type: account?.type || "",
                account_name: account?.account_name || "",
                acc_username: account?.acc_username || "",
                acc_password: account?.acc_password || "",
                phone_number: account?.phone_number || "",
                email: account?.email || "",
                is_active: account?.is_active !== undefined ? account.is_active : true,
                note: account?.note || "",
            });
        }
    }, [open, account]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!account || !account._id) {
            console.error("No account ID provided");
            showToast('Không tìm thấy Account ID', 'error');
            return;
        }
        try {
            setLoading(true);
            await axiosClient.put(`/account/${account._id}`, formData);
            onUpdate(account._id, formData);
            showToast('Cập nhật Tài khoản thành công');
            onClose();
            console.log('Form data submitted:', formData);
        } catch (error) {
            console.error("Error updating account:", error);
            showToast(error.response?.message || error.message, 'error');
        }finally{
            setLoading(false);
        }
    };

    return (
        open && (
            <AccountForm 
                account={formData} 
                handleSubmit={handleSubmit} 
                onChange={handleChange} 
                onClose={onClose} 
                loading={loading}
            />
        )
    );
};

export default EditAccountDialog;
