import React, { useState, useEffect } from "react";
import axiosClient from "../../api/axiosClient";
import { Dropdown, PasswordInput, TextInput } from "../../components/UIComponents";
import { STATUS } from "../../utils/constants";
import AvatarUploader from "../../UploadFile/AvatarUploader";
import { Toast } from "../../messages/Toast";
import Loader from "../../components/Loader";
import { getRoleUser } from "../../utils/utils";

const EditUserDialog = ({ open, onClose, user, onUpdate }) => {
  const showToast = Toast();
  const [selectedGroupId, setSelectedGroupId] = useState(user?.group_id || '');
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const userAuth = JSON.parse(sessionStorage.getItem('user'));

  const [formData, setFormData] = useState({
    group_id: user?.group_id?._id,
    team_id: user?.team_id?._id,
    full_name: user?.full_name,
    role: user?.role,
    is_active: user?.is_active,
    avatar: user?.avatar,
    username: user?.username || '',
    password: user?.password || '',
    note: user?.note
  });

  useEffect(() => {
    if (user) {
      setFormData({
        group_id: user?.group_id?._id,
        team_id: user?.team_id?._id,
        full_name: user?.full_name,
        role: user?.role,
        is_active: user?.is_active,
        avatar: user?.avatar,
        username: user?.username || '',
        password: user?.password || '',
        note: user?.note
      });
    }
  }, [user]);

  console.log("🚀 ~ EditUserDialog ~ formData:", formData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));// func set form
  };

  const handleAvatarUpload = (avatarUrl) => {
    setFormData({
      ...formData,
      avatar: avatarUrl,
    });
  }

  useEffect(() => {
    if (user?.group_id) {
      setSelectedGroupId(user.group_id);
    }
  }, [user?.group_id]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        setLoading(true);
        const response = await axiosClient.get('/group');
        setGroups(response);
        const currentGroup = response.filter(item => item._id == formData.group_id)
        console.log("🚀 ~ fetchGroups ~ currentGroup:", currentGroup)
        handleGroupChange({ target: { value: currentGroup[0]?._id, name: currentGroup[0]?.group_name } });
      } catch (error) {
        console.error('Error fetching groups:', error);
        showToast(error.response?.message || error.message, 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  const validateFormData = (formData) => {
    const formDataCopy = { ...formData };
    if (!formDataCopy.username) delete formDataCopy['username'];
    if (!formDataCopy.password) delete formDataCopy['password'];

    return formDataCopy;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axiosClient.put(`/user/${user._id}`, validateFormData(formData));
      onUpdate(user._id, response);
      onClose();
    } catch (error) {
      console.error("Error updating User:", error);
      showToast(error.response?.message || error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleGroupChange = async (e) => {
    const { name, value } = e.target;
    setSelectedGroupId(value);
    handleChange({ target: { name, value } });
    try {
      setLoading(true);
      const response = await axiosClient.get(`/team/by-group/${value}`);

      const listTeams = Array.isArray(response) ? response : [];
      setTeams(listTeams);

      if (listTeams.length > 0) {
        // Set the team_id to the first team in the list
        setFormData(prevFormData => ({
          ...prevFormData,
          team_id: listTeams[0]._id
        }))
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          team_id: ''
        }))
      }
    } catch (error) {
      console.error("Error fetching teams:", error);
      showToast(error.response?.message || error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    open && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-full max-w-2xl mx-auto rounded-lg shadow-lg p-6 z-50">
          <h2 className="text-2xl font-semibold mb-4">{!user ? "Tạo" : "Sửa"} Nhân viên</h2>
          <div className="flex space-x-4">
            {/* Cột đầu tiên */}
            <div className="w-1/2 space-y-4">
              <TextInput
                fieldName="username"
                labelName="Username"
                defaultValue={user.username}
                onChange={handleChange}
              />
              <PasswordInput
                fieldName="password"
                labelName="Password"
                defaultValue={user?.password}
                onChange={handleChange}
                is_required
              />
              <TextInput
                fieldName="full_name"
                labelName="Họ tên"
                defaultValue={user?.full_name}
                onChange={handleChange} />
              <Dropdown
                options={groups.map(group => ({ value: group._id, label: group.group_name }))}
                fieldName="group_id"
                labelName="Khu"
                defaultValue={formData.group_id}
                onChange={handleGroupChange}
              />
              <Dropdown
                options={teams.map(team => ({ value: team._id, label: team.team_name }))}
                fieldName="team_id"
                labelName="Tổ"
                defaultValue={formData.team_id || teams[0]?.id}
                onChange={handleChange}
              />
              <Dropdown
                options={getRoleUser(userAuth)}
                fieldName="role"
                labelName="Chức vụ"
                defaultValue={formData?.role}
                onChange={handleChange}
              />
            </div>

            {/* Cột thứ hai */}
            <div className="w-1/2 space-y-4">
              <Dropdown
                options={STATUS}
                fieldName="is_active"
                labelName="Trạng Thái"
                defaultValue={formData.is_active}
                onChange={handleChange}
              />
              <TextInput fieldName="note" labelName="Ghi Chú" defaultValue={user?.note} onChange={handleChange} />
              <AvatarUploader onUpload={(handleAvatarUpload)} defaultAvatar={formData?.avatar} />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Đóng
            </button>
            <button
              onClick={handleSubmit}
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Sửa
            </button>
          </div>
        </div>
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={onClose}
          style={{ pointerEvents: "auto" }}
        ></div>
        {loading && <Loader />}
      </div>
    )

  );
};

export default EditUserDialog;

