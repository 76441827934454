const USER_ROLES = {
    S_ADMIN: "s_admin",
    M_ADMIN: "m_admin",
    COACH: "coach",
    T_LEADER: "t_leader",
    MEMBER: "member"
}

const SOCIAL_TYPE =
    [
        { value: 'zalo', label: 'Zalo' },
        { value: 'facebook', label: 'Facebook' },
        { value: 'telegram', label: 'Telegram' },
        { value: 'email', label: 'Email' },
    ];

const ROLE_TYPE =
    [
        { value: 'm_admin', label: 'Quản lý OA' },
        { value: 'coach', label: 'Huấn Luyện Viên' },
        { value: 't_leader', label: 'Tổ Trưởng' },
        { value: 'member', label: 'Nhân viên' },
    ];

const STATUS =
    [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' }
    ];

const DEFAULT_IMG = `${process.env.PUBLIC_URL}/avatar-default.jpg`;
const DEFAULT_IMG2 = `${process.env.PUBLIC_URL}/sh-logo.jpg`;


const ADMIN_AUTHORIZE = [USER_ROLES.S_ADMIN, USER_ROLES.M_ADMIN, USER_ROLES.COACH, USER_ROLES.T_LEADER]
const SUPER_AUTHORIZE = [USER_ROLES.S_ADMIN, USER_ROLES.M_ADMIN]
const COACH_AUTHORIZE = [USER_ROLES.COACH]
const MANAGE_ACCOUNT = [USER_ROLES.M_ADMIN, USER_ROLES.MEMBER]
const ALL_ROLE = [USER_ROLES.S_ADMIN, USER_ROLES.M_ADMIN, USER_ROLES.COACH, USER_ROLES.T_LEADER, USER_ROLES.MEMBER]

module.exports = {
    USER_ROLES,
    ADMIN_AUTHORIZE,
    SUPER_AUTHORIZE,
    COACH_AUTHORIZE,
    MANAGE_ACCOUNT,
    ALL_ROLE,
    SOCIAL_TYPE,
    ROLE_TYPE,
    STATUS,
    DEFAULT_IMG,
    DEFAULT_IMG2
}