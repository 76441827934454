import { data } from "autoprefixer";
import axios from "axios";
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.okvipmng.com/api",
});
console.log(
  "🚀 ~ process.env.REACT_APP_API_URL:",
  process.env.REACT_APP_API_URL
);
console.log(
  "🚀 ~ process.env.REACT_APP_WS:",
  process.env.REACT_APP_WS
);

axiosClient.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response.data && response.data.data !== undefined) {
      return response.data.data;
    }
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem("token");
      window.location.href = "/login";
    }
    if (error.status === 500) {
      return Promise.reject(error);
    } else {
      return error.response.data;
    }
  }
);

export default axiosClient;
