import React, { useState } from "react";
import axiosClient from "../../api/axiosClient";
import AccountForm from "./AccountForm";
import { Toast } from "../../messages/Toast";
import Loader from "../../components/Loader";

const CreateAccountDialog = ({ user_id, open, onClose, onCreate }) => {
    const showToast = Toast();
    const [loading, setLoading] = useState();

    const [formData, setFormData] = useState({
        user_id: user_id,
        type: "zalo",
        account_name: "",
        acc_username: "",
        acc_password: "",
        phone_number: "",
        email: "",
        is_active: true,
        note: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await axiosClient.post("/account", formData);
            onCreate(response);
            showToast('Taọ Tài Khoản Thành Công')
            onClose();
        } catch (error) {
            console.error("Error creating account:", error);
            showToast(error.response?.message || error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        open && <AccountForm account={formData} handleSubmit={handleSubmit} onClose={onClose} onChange={handleChange} loading={loading} />
    );
};

export default CreateAccountDialog;
